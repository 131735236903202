var $window = $(window);
var $body = $('body');
window.page = $body.data('page');
var $header = $('#header');
var $wrapper = $('#wrapper');
var spaceToHeaderFixed;
var language = $('html').attr('lang');
var timeTransitionDefault = 350;
var texts = [];

texts['pt-BR'] = {
  quantity: 'quantidade',
  price: 'preço',
  cart_empty: 'O seu carrinho está vazio',
  go_product: 'Ir para a página do produto',
  btn_edit_cart: 'Editar pedido',
  btn_checkout: 'Finalizar pedido',
  btn_success: 'Adicionado!',
  errorsTxt: {
    withoutSku: 'Selecione um atributo para o produto'
  }
};

function getText (key, subKey) {
  if (typeof subKey == 'undefined') {
    return texts[language][key];
  } else {
    return texts[language][key][subKey];
  }
}

window.store = {
  config: {
    // Seletor do botão de comprar
    selectorBtnBuy: '[data-action="add-cart"]',
    selectorBtnBuyAssinatura: '[data-action="add-cart-assinatura"]',
    // Seletor do elemento que engloba um produto
    selectorWrapperProduct: '[data-box-produto]',
    selectorWrapperProductAssinatura: '[data-box-produto-assinatura]',
    selectorFormProduct: '[data-form-product]',
    selectorFormProductAssinatura: '[data-form-product-assinatura]',
    // Se a store tem versão internacional ou não
    hasInternacionalVersion: false,
    errorsTxt: {
      withoutSku: getText('errorsTxt', 'withoutSku'),
    },
    // configuração de comportamento ao add um produto
    addToCartOpenCartpopup: true,
    addToCartOpenCartpopupMobile: true,
    cartCount: {
      selector: '[data-cart-count]',
      attr: '[data-cart-count-attr]',
      text: '[data-cart-item-text]'
    }
  },
  formatMoney: function (value) {
    // FORMATA UM VALOR
    return "R$ " + value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
  },
  validateQuantity: function (_val) {
    // VALIDA SE A QUANTIDADE INFORMADA É UM NÚMERO
    if (!isNaN(_val)) {
      if (parseInt(_val) > 0) {
        return true;
      }
    }

    return false;
  },
  getClearNumber: function (_val) {
    // RETORNA UM NÚMERO LIMPO COMO INT
    if (!isNaN(_val)) {
      clearNumber = parseInt(_val);

      return clearNumber;
    }

    return false;
  },
  validateFormProduct: function (_$form) {
    // VALIDA O FORM DE PRODUTO PARA VER SE O PRODUTO PODE SER ADICIONADO
    var $parent = _$form.closest('[data-box-produto]');
    var $btnComprar = _$form.find('[data-action="add-cart"]');
    var validated = true;
    var errorsTxt = [];

    if ($btnComprar.data('available') == 'false') {
      validated = false;
      errorsTxt.push('');
    } else {
      //  verifica se o sku foi selecionado
      if (_$form.find('[name="sku"]').val() == '') {
        validated = false;
        errorsTxt.push('Selecione um atributo para o produto');
      }

      //  verifica se a quantidade é válida
      //console.log(_$form.find('input[name="quantity"]').val());
      if (_$form.find('input[name="quantity"]').val() <= 0) {
        //console.log("qtd invalida");
        validated = false;
        errorsTxt.push('Quantidade indisponível');
      }
    }

    return {validated: validated, errors: errorsTxt};
  },
  setRespValidateProduct: function (_resp, _$form, _$boxResponse) {
    // var $boxResult = _$form.find('.resp-validate');
    var $boxResult = _$boxResponse;
    var htmlErrors = '';

    if (_resp.validated) {
      $boxResult.empty();

    } else {
      for (var i = _resp.errors.length - 1; i >= 0; i--) {
        htmlErrors += '<span class="msg error">' + _resp.errors[i] + '</span>';
      }

      $boxResult.html(htmlErrors);
    }
  },
  addItemResultDefault: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this = this;
    if (_typeResult == 'produto-adicionado') {
      var $btnComprar = _$form.find(_this.config.selectorBtnBuy);

      $btnComprar.addClass('success').html('Produto adicionado!');

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar.removeClass('success').html($btnComprar.data('text-available'));
      }, 3500);

      setTimeout(function () {
        window.location.href = urlCart;
      }, 150);

    } else if (_typeResult == 'erro-adicionar') {
      var errorText = JSON.parse(_result.responseText);

      if (typeof(_$boxResponse) != 'undefined' && _$boxResponse.length > 0) {
        //console.info(errorText);

        _$boxResponse.addClass('error');
        _$boxResponse.removeClass('success');

        _$boxResponse.find('span').html(errorText.error);
        _$boxResponse.slideDown(350);

        setTimeout(function () {
          _$boxResponse.slideUp(350);
        }, 3500);
      }
    }
  },
  addItemResultDefaultAssinatura: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this = this;
    if (_typeResult == 'produto-adicionado') {
      var $btnComprar = _$form.find(_this.config.selectorBtnBuyAssinatura);

      $btnComprar.addClass('success').html('Adicionado!');

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar.removeClass('success').html($btnComprar.data('text-available'));
      }, 3500);

      setTimeout(function () {
        window.location.href = urlAssinaturaCart;
      }, 150);

    } else if (_typeResult == 'erro-adicionar') {
      if (typeof _result == 'object') {
        var errorText = _result;
      } else {
        var errorText = JSON.parse(_result);
      }

      if (typeof (_$boxResponse) != 'undefined' && _$boxResponse.length > 0) {
        //console.info(errorText);

        _$boxResponse.addClass('error');
        _$boxResponse.removeClass('success');

        _$boxResponse.find('span').html(errorText.error);
        _$boxResponse.slideDown(350);
      }
    }
  },
  addItem: function (_$form, _$parent, addItemResult) {
    var _this = this;
    var $btnComprar = _$parent.find('[data-action="add-cart"]');
    var urlAdd = '/carrinho/adicionar';
    var urlUpdate = '/carrinho';
    var url = urlAdd;
    var form = _$form.serialize();
    var $boxResponse = _$parent.find('[data-form-product] .msg-response:not(.resp-validate)');

    //console.info('addItem');
    //console.info('addItem', _$form.serializeArray());

    if (!$btnComprar.hasClass('adding')) {
      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: form,
        beforeSend: function () {
          $btnComprar.addClass('adding');
        }
      })
      .done(function(resp) {
        //console.log("success");
        //console.info(resp);

        if (typeof template_store.addItemResult == 'function') {
          template_store.addItemResult(_$form, 'produto-adicionado', resp, $boxResponse);
        } else {
          _this.addItemResultDefault(_$form, 'produto-adicionado', resp, $boxResponse);
        }
      })
      .fail(function(resp) {
        //console.log("error");
        //console.error(resp);
        //console.error(resp.responseText);
        //console.error(resp.responseText.error);

        if (typeof addItemResult == 'function') {
          addItemResult(_$form, 'erro-adicionar', resp, $boxResponse);
        } else {
          _this.addItemResultDefault(_$form, 'erro-adicionar', resp, $boxResponse);
        }
      })
      .always(function() {
        //console.log("complete");
        $btnComprar.removeClass('adding');
      });
    }
  },
  addItemAssinatura: function (_$form, _$parent, addItemResult) {
    var _this = this;
    var $btnComprar = _$parent.find(store.config.selectorBtnBuyAssinatura);
    var url = urlAssinaturaCartAdd;
    var form = _$form.serialize();
    var $boxResponse = _$parent.find(store.config.selectorFormProductAssinatura + ' .msg-response:not(.resp-validate)');
    var data = {};

    data.subscription_item = {
      quantity: _$form.find('[name="quantity"]').val(),
      sku: _$form.find('[name="sku"]').val(),
      extra: {
        vindi_plan_id: _$form.find('[name="extra[vindi_plan_id]"]').val()
      }
    }

    //console.info('addItemAssinatura');
    //console.info('addItemAssinatura', data);
    //console.info('addItemAssinatura', JSON.stringify(data));

    if (!$btnComprar.hasClass('adding')) {
      $.ajax({
        url: url,
        type: 'POST',
        // dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        xhrFields: { withCredentials: true },
        beforeSend: function () {
          $btnComprar.addClass('adding');
        }
      })
        .done(function (resp) {
          //console.log("success");
          //console.info(resp);

          if (typeof addItemResult == 'function') {
            addItemResult(_$form, 'produto-adicionado', resp, $boxResponse);
          } else {
            _this.addItemResultDefaultAssinatura(_$form, 'produto-adicionado', resp, $boxResponse);
          }
        })
        .fail(function (xhr, status, error) {
          //console.log("error");
          //console.error(xhr);
          //console.error(status);
          //console.error(error);

          var resp = {
            error: 'Houve um problema ao adicionar. Tente mais tarde ou entre em contato.',
          }

          if (typeof addItemResult == 'function') {
            addItemResult(_$form, 'erro-adicionar', resp, $boxResponse);
          } else {
            _this.addItemResultDefaultAssinatura(_$form, 'erro-adicionar', resp, $boxResponse);
          }
        })
        .always(function () {
          //console.log("complete");
          $btnComprar.removeClass('adding');
        });
    }
  },
  deleteItem: function (_itemId, _$item, removeItemResult) {
    var _this = this;

    if (!_$item.hasClass('removing')) {
      $.ajax({
        url: '/carrinho',
        type: 'POST',
        dataType: 'json',
        data: {
          '_method': 'delete',
          'item_id': _itemId
        },
        beforeSend: function () {
          _$item.addClass('removing');
        }
      })
      .done(function(_cart) {
        //console.log("success");

        if (typeof removeItemResult == 'function') {
          removeItemResult(_$item, _cart);
        }
      })
      .fail(function(error) {
        //console.log("erro ao remover", error.responseText);
      })
      .always(function() {
        _$item.removeClass('removing');
      });
    }
  },
  setMsgResponse: function (_msg, _type, _$parent) {
    /*
      O tipo pode conter um dos 3 valores: success, warning, error ou CLEAR.
      O type CLEAR limpa as mensagens e faz sumir a mensagem
    */

    // Verifica se não há mais de uma mensagem
    // caso tenha, gera um html para cada mensagem
    if (Array.isArray(_msg)) {
      var msgArr = _msg;
      _msg = '';
      for (var i = msgArr.length - 1; i >= 0; i--) {
        _msg += '<span class="msg error">' + msgArr[i] + '</span>';
      }
    }

    _$parent
      .find('[data-msg-retorno]')
        .removeClass('success')
        .removeClass('warning')
        .removeClass('error');

    _$parent.find('[data-msg-retorno] [data-msg]').empty();

    if (_type != 'clear') {
      _$parent.find('[data-msg-retorno]').addClass(_type);
      _$parent.find('[data-msg-retorno] [data-msg]').html(_msg);
    }
  },
  updateCartCount: function (_itemsCount) {
    var _this = this;

    // $(_this.cartCount.selector).empty().text(_itemsCount);

    $(_this.config.cartCount.attr).data('cart-count-attr', _itemsCount);
    $(_this.config.cartCount.attr).attr('data-cart-count-attr', _itemsCount);

    $(_this.config.cartCount.text).html(_itemsCount);
    $(_this.config.cartCount.textLabel).html(_itemsCount > 1 ? 'itens' : 'item');
  },
  submitForm: function (_$form) {

    _$form.find('[name="reply_to"]').val(_$form.find('[name="email"]').val());


    if (!_$form.hasClass('sending')) {
      $.ajax({
        url: '/webform',
        type: 'POST',
        data: _$form.serialize(),
        beforeSend: function () {
          _$form.addClass('sending');
        }
      })
      .done(function() {
        // console.info('foi');
        // store.setMsgResponse('Mensagem enviada com sucesso!', 'success', _$form);
        _$form.find('[data-msg-retorno] [data-msg-success]').addClass('visible');
        _$form.find('[data-msg-retorno] [data-msg-error]').removeClass('visible');

        // console.log('testandouuuu', _$form);

        if ($(_$form).hasClass('news') || $(_$form).hasClass('notify-when-arrives')) {

        } else {
          _$form.find("button[type=submit]").text('Enviado');
          setTimeout(function() {
             _$form.find("button[type=submit]").text('Enviar');
          }, 3500);
        }

        _$form[0].reset();
      })
      .fail(function() {
        // console.info('foi, mas deu ruim');
        _$form.find('[data-msg-retorno] [data-msg-success]').removeClass('visible');
        _$form.find('[data-msg-retorno] [data-msg-error]').addClass('visible');

        if ($(_$form).hasClass('news') || $(_$form).hasClass('notify-when-arrives')) {

        } else {
          _$form.find("button[type=submit]").text('Falha ao enviar');
          setTimeout(function() {
             _$form.find("button[type=submit]").text('Enviar');
          }, 3500);
        }
        // store.setMsgResponse('Houve um problema ao enviar sua mensagem, tente novamente! Ou entre em contato conosco pelo telefone (21) 99644-6925 ', 'error', $form);
      })
      .always(function() {
        setTimeout(function() {
          _$form.find('[data-msg-retorno] [data-msg-success]').removeClass('visible');
           _$form.find('[data-msg-retorno] [data-msg-error]').removeClass('visible');
        }, 5000);
        //console.log("complete");
        _$form.removeClass('sending');
      });
    }
  },
  login: {
    validateLogin: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe seu email!');
      }

      if (_$form.find('[name="password"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe a senha!');
      }

      return {validated: validated, errors: errorsTxt};
    },
    validateRegister: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe seu email!');
      }

      if (_$form.find('[name="password"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe uma senha!');
      }

      if (_$form.find('[name="password"]').val() != '') {
        var pass = _$form.find('[name="password"]').val();
        var confirmationPass = _$form.find('[name="password_confirmation"]').val();

        if (pass != confirmationPass) {
          validated = false;
          errorsTxt.push('Senha e confirmação são diferentes!');
        }
      }

      return {validated: validated, errors: errorsTxt};
    },
    validateRecover: function (_$form) {
      var validated = true;
      var errorsTxt = [];

      if (_$form.find('[name="email"]').val() == '') {
        validated = false;
        errorsTxt.push('Informe seu email!');
      }

      return {validated: validated, errors: errorsTxt};
    },
    logar: function (_$form) {
      if (!_$form.hasClass('sending')) {
        $.ajax({
          url: '/entrar',
          type: 'POST',
          dataType: "json",
          data: _$form.serialize(),
          beforeSend: function () {
            _$form.addClass('sending');
          }
        })
        .done(function(resp) {
          //console.log("success", resp);

          if (resp.error) {
            store.setMsgResponse(resp.error, 'error', _$form);
          }

          if (resp.client) {
            window.location.href = resp.redirect_to;
          }
        })
        .fail(function(resp) {
          //console.error("error", error.responseText);
        })
        .always(function() {
          //console.log("complete");
          _$form.removeClass('sending');
        });
      }
    },
    recoverPassord: function (_$form) {
      if (!_$form.hasClass('sending')) {
        $.ajax({
          url: '/recuperar_senha',
          type: 'POST',
          dataType: "json",
          data: _$form.serialize(),
          beforeSend: function () {
            _$form.addClass('sending');
          }
        })
        .done(function(resp) {
          //console.log("success", resp);

          if (resp.error) {
            store.setMsgResponse(resp.error, 'error', _$form);
          }

          if (resp.ok) {
            store.setMsgResponse(resp.ok, 'success', _$form);
          }

          /*if (resp.client) {
            window.location.href = resp.redirect_to;
          }*/
        })
        .fail(function(resp) {
          //console.error("error", error.responseText);
        })
        .always(function() {
          //console.log("complete");
          _$form.removeClass('sending');
        });
      }
    },
  },
}

window.template_store = {
  headerCart: {
    loadCart: function (_cart) {
      var _this = this;
      var $boxCart = $('[data-cart-body]');

      store.updateCartCount(_cart.items_count);

      vnda.loadCartPopup(function (html) {
        $boxCart.empty();
        $boxCart.append(html);
      });
    },
    show: function () {
      var $cartBody = $('.carrinho');

      $('.overlay-modal-carrinho').addClass('open');
      $('.modal-carrinho').addClass('open');

      $cartBody.addClass('active');

      setTimeout(function () {
        $cartBody.removeClass('active');
        $('.overlay-modal-carrinho').removeClass('open');
        $('.modal-carrinho').removeClass('open');
      }, 3500);
    }
  },
  /* manda um ajax para o form de avise-me e também para um form da plataforma para o cliente receber um email  */
  submitFormNotify: function (_$form) {
    var formData = _$form.serialize();

    if (!_$form.hasClass('sending')) {
      $.ajax({
        url: _$form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: formData,
        beforeSend: function () {
          _$form.addClass('sending');
        }
      })
        .done(function (resp) {
          //console.log("success");
          //console.info(resp);

          var msgSuccess = 'Você receberá um e-mail quando o produto estiver disponível';

          // Envia um email para o cliente saber que o usuário solicitou o avise-me
          $.ajax({
            url: '/webform',
            type: 'POST',
            data: {
              key: _$form.find('[name="key"]').val(),
              reply_to: _$form.find('[name="email"]').val(),
              email: _$form.find('[name="email"]').val(),
              product_name: _$form.find('[name="nome"]').val(),
              referencia: _$form.find('[name="referencia"]').val(),
              sku: _$form.find('[name="sku"]').val(),
              tel: _$form.find('[name="tel"]').val(),
            },
          })
            .done(function (resp) {
              _$form.find("button[type=submit]").text("Enviado");
              setTimeout(function () {
                _$form.find("button[type=submit]").text("Enviar");
              }, 3500);
              _$form[0].reset();
            })
            .fail(function (error) {
              _$form.find("button[type=submit]").text("Falha ao enviar");
              setTimeout(function () {
                _$form.find("button[type=submit]").text("Enviar");
              }, 3500);
            })
            .always(function () {
            })

          if (resp.error) {
            _$form.find('.msg-response').removeClass('happy').addClass('error');
            _$form.find('.msg-response span').html(resp.error);
            _$form.find('.msg-response').slideDown(350);

            setTimeout(function () {
              _$form.find('.msg-response').removeClass('happy').removeClass('error');
              _$form.find('.msg-response span').empty();
            }, 3500);

          } else {
            _$form.find('.msg-response').addClass('happy').removeClass('error');
            _$form.find('.msg-response span').html(msgSuccess);

            setTimeout(function () {
              _$form.find('.msg-response').removeClass('happy').removeClass('error');
              _$form.find('.msg-response span').empty();
            }, 3500);

          }
        })
        .fail(function (resp) {
          //console.log("error");
          //console.error(resp);
          //console.error(resp.responseText);
          //console.error(resp.responseText.error);

          var errorText = JSON.parse(resp.responseText);
          //console.info(errorText);


          _$form.find('.msg-response').removeClass('happy');
          _$form.find('.msg-response span').html(errorText.error);
          _$form.find('.msg-response').slideDown(350);

          setTimeout(function () {
            $_$form.find('.msg-response').slideUp(350);
          }, 3500);
        })
        .always(function () {
          _$form.removeClass('sending');
        });
    }
  },
  /* Função que executa o comportamento de quando o produto é adicionado no carrinho */
  addItemResult: function (_$form, _typeResult, _result, _$boxResponse) {
    var _this;

    // console.log('oiiiii')

    if (_typeResult == 'produto-adicionado') {
      var $btnComprar = _$form.find(store.config.selectorBtnBuy);

      $btnComprar.addClass('success').html(getText(language));

      setTimeout(function () {
        // _$form.find('.msg-response').slideUp(350);
        $btnComprar.removeClass('success').html($btnComprar.data('text-available'));
      }, 3500);

      if ($body.width() > 768) {
        if (store.config.addToCartOpenCartpopup) {
          template_store.headerCart.loadCart(_result);
          template_store.headerCart.show();
        } else {
          setTimeout(function () {
            window.location.href = urlCart;
          }, 150);
        }
      } else {
        if (store.config.addToCartOpenCartpopupMobile) {
          template_store.headerCart.loadCart(_result);
          template_store.headerCart.show();
        } else {
          setTimeout(function () {
            window.location.href = urlCart;
          }, 150);
        }
      }

    } else if (_typeResult == 'erro-adicionar') {
      var errorText = JSON.parse(_result.responseText);

      if (typeof (_$boxResponse) != 'undefined' && _$boxResponse.length > 0) {
        $('html,body').animate({
          scrollTop: _$boxResponse.offset().top
        }, '250');

        //console.info(errorText);

        _$boxResponse.addClass('error');
        _$boxResponse.removeClass('success');

        _$boxResponse.find('span').html(errorText.error);
        _$boxResponse.slideDown(350);

        setTimeout(function () {
          _$boxResponse.slideUp(350);
        }, 3500);
      }
    }
  },
  setSubmenu: function () {
    $(document).ready(function() {
      $( '.item-menu' ).hover(
        function() {
          $(this).children('.submenu-container').removeClass('hide');
        },
        function() {
          $('.submenu-container').addClass('hide');
        }
      );
    });
  },
  setHeaderScroll: function() {
      $(document).ready(function() {
        $(window).on('scroll', function() {
          if ($(window).scrollTop() >= 1) {
            $('.logo').addClass('logo-scroll');
            $('.header').addClass('header-scroll');
            $('.submenu-container').addClass('submenu-scroll');

          } else {
              $('.logo').removeClass('logo-scroll');
              $('.header').removeClass('header-scroll');
              $('.submenu-container').removeClass('submenu-scroll');
          }
        });
      });
  },
  setFooterScroll: function() {
    if ($(window).width() > 992) {
      $(document).ready(function() {
        $(window).on('scroll', function() {
          if ($(window).scrollTop() >= 20) {
            $('.footer').addClass('footer-fixed');
            }
            else {
              $('.footer-fixed').removeClass('footer-fixed');
          }
        });
      });
    }
  },
  setCloseBannerText: function() {
    $('#close').click(function(event){
     // $('.header').css("margin-top", "0px");
      //$('main').css("padding-top", "350px");
        event.preventDefault();
    });
  },
  menuMobile: function () {
    jQuery(document).ready(function( $ ) {
      $("#menu").mmenu({
         "extensions": [
            "fx-menu-zoom"
         ],
         "iconbar": {
            "add": true,
            "top": [
               "<a href='/'><i class='fa fa-home'></i></a>",
               "<a href='/entrar'><i class='fa fa-user'></i></a>"
            ],
            "bottom": [
               "<a href='https://www.instagram.com/tejatstudio/' target='_blank'><i class='fa fa-instagram'></i></a>",
            ]
         }
      });
    });
  },
  trocaImagemProduto: function(){
    $('[data-image-variant]').on('click', function(){
      // console.log($(this));
      var src = $(this).data('image-variant');
      // console.log(src);
      var $produto = $(this).closest('.product-block');
      // console.log($produto);
      $produto.find('.image-prod').find('img').attr('src', src);
      // console.log($produto.find('.image-prod').find('img'));
    });
  },
  home: {
    setSliderProducts: function() {
      $(document).ready(function () {
        var swiperFullbanner = new Swiper('.fullbanner-desk', {
          slidesPerView: 1,
          autoplay: {
            delay: 5000,
          },
          lazy: {
            checkInView: true,
            loadOnTransitionStart: true
          }
        });

        var swiperFullbannerMob = new Swiper('.fullbanner-mob', {
          slidesPerView: 1,
          autoplay: {
            delay: 5000,
          },
          lazy: {
            checkInView: true,
            loadOnTransitionStart: true
          }
        });

        var swiper = new Swiper('.slider-produtos', {
          slidesPerView: 1,
          spaceBetween: 25,
          //loop: true,
          slidesPerColumn: 1,
          autoHeight: true,
          navigation: {
            nextEl: $('.products').find('.swiper-button-next'),
            prevEl: $('.products').find('.swiper-button-prev'),
          },
          breakpoints: {
            500: {
              slidesPerView: 2,
              slidesPerColumn: 2,
              spaceBetween: 10
            },
            767: {
              slidesPerView: 2,
              slidesPerColumn: 2,
              spaceBetween: 25
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 25,
              slidesPerColumn: 1
            },
          }
        });
      });
    },
    init: function () {
      _this = this
      _this.setSliderProducts();

      var instagramSlider = new Swiper('.instagramSlider', {
        slidesPerView: 1.5,
        spaceBetween: 25,
        navigation: {
          nextEl: $('.instagram').find('.swiper-button-next'),
          prevEl: $('.instagram').find('.swiper-button-prev'),
        },
        breakpoints: {
          // when window width is <= 992px
          5000: {
            slidesPerView: 5,
          },
          1250: {
            slidesPerView: 3,
          },
          // when window width is <= 992px
          991: {
            slidesPerView: 2,
          },

          600: {
            slidesPerView: 1,
            initialSlide: 0,
          },
        },
      });

      $('.instagram .post').css('height', $('.instagram .post').width());
    }
  },

  tag: {
    init: function () {


      // $('.filtros-reset').click(function(){
      //   var ulrPage = window.location.href;
      //   var urlSplited = ulrPage.split('?');
      //   alert("oioioi");
      //   $('.item input').each(function(index,el){
      //     console.log('filtro item')
      //     $(el).removeAttr('checked')
      //     $(el).change();
      //   })
      //   location.assign("https://www.w3schools.com");
      // });


      $(document).ready(function(){
        $(function () {



         var divof = $( ".filtros-fixos" );
         var offset = divof.offset();
         var topoof = offset.top;
         topoof = topoof;

         var alturaHeader = $( 'header').height();



      $(window).scroll(function () {
        if ($(window).scrollTop() >= 1 && $(window).scrollTop() <= ($(document).height() - 500)) {
            alturaHeader = $( 'header.fixed').height();
            $( ".filtros-fixos" ).addClass('fixo');
            $('.filtros-fixos').css('top', alturaHeader);

        } else {
          alturaHeader = $( 'header.fixed').height();
            $( ".filtros-fixos" ).removeClass('fixo');

        }
      });
    });

       /* var divof = $( ".filtros-fixos" );
        var offset = divof.offset();
        var topoof = offset.top;
        topoof = topoof - 845;

        $(window).on('scroll', function() {
          var alturaHeader;
                     setTimeout(function () {
          alturaHeader = $( 'header.fixed').height();
          }, 400);

          if($(window).scrollTop() >= topoof) {
            $( ".filtros-fixos" ).addClass('fixo');
               setTimeout(function () {
            $('.filtros-fixos').css('top', alturaHeader);
          }, 500);

            //console.log("chegou");
          } else if($(window).scrollTop() < topoof){
            $( ".filtros-fixos" ).removeClass('fixo');
            $('.filtros-fixos').css('top', ' ');
          }
        });*/

        $('#filtro-categoria').click(function(){
            $(this).toggleClass('aberto');
            $('.itens-cat').slideToggle('.350');
            //$('.itens-filtro').toggleClass('aberto');
        });

        $('#filtro-categoria-mobile').click(function(){
            $(this).addClass('aberto');
            $('.itens-filtro').addClass('aberto');
            // $('.itens-cat').slideToggle('.350');
            //$('.itens-filtro').toggleClass('aberto');
        });

        $('.itens-filtro .close').click(function(){
          $(this).removeClass('aberto');
          $('.itens-filtro').removeClass('aberto');
          // $('.itens-cat').slideToggle('.350');
          //$('.itens-filtro').toggleClass('aberto');
      });

        $('#filter-ordena').click(function(){
            $(this).toggleClass('aberto');
            $('.itens-ordena').slideToggle('.350');
            //$('.itens-filtro').toggleClass('aberto');
        });
      });


    }
  },

  infiniteScroll:  {
        $productBox: $(".list-products"),
        $pagination: $(".pagination"),
        $paginationTotal: $(".pagination").data("total-pages"),
        $paginationCurrentPage: $(".pagination").data("actual-page"),
        $paginationNextUrl: $(".pagination").data("next-url") || '',
        $loadMoreButton: $("[data-ver-mais]"),

        loadProducts: function() {

          var _this = this;
          this.changeState("is-loading", "Mais produtos");

          $.ajax({
              cache: false,
              type: "GET",
              url: _this.$paginationNextUrl,
              dataType: "html",
          })
          .done(function(html) {
            //console.log("done");
            _this.update(html);


          })
          .fail(function(resp) {
            //console.log("fail: ", resp);
          });
        },
        update: function(html) {
          this.$productBox.append($(html).find('.product-block'));
          this.$paginationCurrentPage = $(html).find(".pagination").data("actual-page");
          this.$paginationNextUrl = $(html).find(".pagination").data("next-url") || '';
          $(".list-products").removeClass('carregando');
          //var altura_imagens = $('.product_block .images img').height();
          // $('.product_block .images').css('height', altura_imagens);
          // $('.product_block .images img').css('height', altura_imagens);
        },
        changeState: function(state, text) {
          this.$loadMoreButton.removeClass();
          if (state != "") {
            this.$loadMoreButton.addClass(state);
            this.$loadMoreButton.find(".more").text(text);
          }
        },
        setLoader: function() {
          //console.log('oie3');

          this.$loadMoreButton = $("[data-ver-mais]");
          this.$loadMoreButton.removeClass("hide");
          var _this = this;

          this.$loadMoreButton.on("click", function(event) {
            event.preventDefault();

            if (_this.$paginationCurrentPage !== _this.$paginationTotal)  {
              _this.loadProducts();
            } else {
              //console.log("fim");
            }
          });

          $(window).on("scroll", function() {

            var scrollado = $(window).scrollTop() + $(window).height();
            var target = $("footer").offset().top;

            //console.log(target);
            if (scrollado > target) {
              if (_this.$paginationCurrentPage !== _this.$paginationTotal)  {
                if(!$(".list-products").hasClass('carregando')){
                  $(".list-products").addClass('carregando');
                  _this.loadProducts();
                }
              } else {
                //console.log("fim");
              }
            }
          });
        },
        init: function() {
          //console.log('oie');
          if (this.$paginationCurrentPage !== this.$paginationTotal)  {
            //console.log('oie2');
            this.setLoader();
          }
        }
    },


  product: {
    $sectionProduct: $('[data-section-product]'),
    $wrapperImages: $('[data-section-product]').find('[data-product-images]'),
    $sliderProduct: $('[data-section-product]').find('[data-product-images] [data-main-slider]'),
    $thumbsProduct: $('[data-section-product]').find('[data-product-images] [data-slider-thumbs]'),
    sliderProduct: [],
    /*
      Exibe as primeiras imagens do produto
      ** Importante para quando houver imagem por sku **
    */
    showFirstImages: function () {
      var _this = this;
      var variants = vnda.variants.all();
      var $imagesWithoutSkus = _this.$sliderProduct.find('.image-without-sku');
      var $thumbsWithoutSkus = _this.$thumbsProduct.find('.image-without-sku');

      if ($imagesWithoutSkus.length > 0) {

        /*
          Todas as imagens sem skus são exibidas sempre
        */

        $imagesWithoutSkus
          .removeClass('inactive')
          .addClass('active');

        $thumbsWithoutSkus
          .removeClass('inactive')
          .addClass('active');
      } else {
        /*
          Percorre todas as variantes
        */
        for (var i = 0; i < variants.length; i++) {
          if (variants[i].main) {
            //console.info(variants[i]);

            // Procura a imagem que contém o sku e a exibe
            _this.$sliderProduct.find('[data-image][data-skus-image*="' + variants[i].sku + '"]')
              .addClass('active')
              .removeClass('inactive');

            _this.$thumbsProduct.find('[data-image-thumb][data-skus-image*="' + variants[i].sku + '"]')
              .addClass('active')
              .removeClass('inactive');
          }
        }
      }
    },
     /*productTabs: function () {
      var tabButtons = document.querySelectorAll('[data-product-tabs] .tabs .tab');
      var tabContents = document.querySelectorAll('[data-product-tabs] .content .tab-content');

      for (var i = 0; i < tabButtons.length; i++) {
        tabButtons[i].addEventListener('click', function(e) {
          e.preventDefault();
          var id = this.hash.replace('#', '');

          for (var j = 0; j < tabContents.length; j++) {
            var tabContent = tabContents[j];
            tabContent.classList.remove('visible');
            tabButtons[j].classList.remove('active');
            if (tabContent.id === id) {
              tabContent.classList.add('visible');
            }
          }

          this.classList.add('active');
        });
      }

    },*/
    setColorsTab: function (){
        var colors = document.querySelectorAll('[data-produto="first-attribute"] label');

        for (var i=0; colors.length > i; i++) {
          colors[i].addEventListener('click', function () {
            var optionSelected = this.cloneNode(true);
            // console.log(optionSelected)
            document.getElementById('color-visible').innerHTML = '';
            document.getElementById('color-visible').appendChild(optionSelected);
            this.parentNode.parentNode.classList.remove('visible');
          })
        }


        document.getElementById('option-1').addEventListener('click', function(e){
          e.preventDefault();

           if ($(e.target).closest('.inner .value-color').length)
        return;
          $(this).find('.wrapper-inner').toggleClass('visible');
        });


    },
    init: function () {
      // _this.showFirstImages();
       _this = this;

     // _this.productTabs();
      _this.setColorsTab();

      if ($('[data-form-notify]').length > 0) {
        $(document).on('submit', '[data-form-notify]', function (event) {
          event.preventDefault();
          template_store.submitFormNotify($(this));
        });
      }

      $(document).on('click', '.avise-me-btn', function(e){
        e.preventDefault();
        $('.notify-when-arrives').addClass('active')
      })

      $('.notify-when-arrives .close').on('click', function(){
        $('.notify-when-arrives').removeClass('active')
      })


      console.log('deep1')
       var swiperProd = new Swiper('.swiper-prod-mobile', {
        slidesPerView: 1,
        // loop: true,
        // autoplay: {
        //   delay: 5000,
        // },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: {
          loadPrevNext: false,
          Amount: 1,
          loadOnTransitionStart: true
        },
        pagination: {
          el: '.pagination-product',
          clickable: true,
        },
        //swiper lazy load
        preloadImages: false,
        watchVisibility: true,
        lazy: {
          loadPrevNext: false,
          loadOnTransitionStart: true
        }
      });

      template_store.product.sliderProduct.push(swiperProd)

      $(document).ready(function(){
        $('.guia-tamanhos-button').click(function(){
          $('.guia-medidas').addClass('aberto');
        });

        $('.guia-tamanhos-button').click(function(e){
           e.stopPropagation();
        });

        $('.guia-medidas').click(function(e){
           e.stopPropagation();
        });

        $('body').click(function(event){
          $('.guia-medidas').removeClass('aberto');
        });

        $('.guia-medidas .close').on('click', function(){
          $('.guia-medidas').removeClass('aberto');
        })

        $('.item-tab-medidas:first-child').addClass('aberto');
        $('.guia-medidas-item:first-child').addClass('aberto');

        $('.item-tab-medidas').click(function(){
          $('.item-tab-medidas').removeClass('aberto');
          $('.guia-medidas-item').removeClass('aberto');
          $(this).addClass('aberto');
          var idMedida = $(this).attr('id');
          var conteudoMedida = "#content_" + idMedida;
          $(conteudoMedida).addClass('aberto');
        });

        $('.titulo-tam').click(function(){
          $(this).siblings('.tamanho-itens').toggleClass('aberto');
          $(this).toggleClass('aberto');
        });

        $('.inner label').click(function(){
            $('.titulo-tam').removeClass('aberto');
        });

        var swiperRelacionados = new Swiper('.produtos-relacionados-slider', {
          slidesPerView: 1,
          spaceBetween: 25,
          //loop: true,
          slidesPerColumn: 1,
          //autoHeight: true,

          breakpoints: {
            500: {
              slidesPerView: 2,
              slidesPerColumn: 2,
              spaceBetween: 10
            },
            767: {
              slidesPerView: 2,
              slidesPerColumn: 2,
              spaceBetween: 25
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 25,
              slidesPerColumn: 1
            },
          }
        });
      });

      $(document).on('submit', store.config.selectorFormProduct, function (event) {
        event.preventDefault();

        var $form = $(this);
        var $parent = $form.closest(store.config.selectorWrapperProduct);

        var $btnComprar = $parent.find(store.config.selectorBtnBuy);

        var respValidated = store.validateFormProduct($form);
        var $boxResponse = $parent.find('.resp-validate');

        if (respValidated.validated) {
          store.addItem($form, $parent);
        } else {
          //console.info('não foi');
          store.setRespValidateProduct(respValidated, $form, $boxResponse);
        }
      });


    }
  },
  login: {
    init: function () {
      $('[data-content-login]').each(function (index, el) {
        // Animação
        $(el).on('click', '[data-forget-password]', function (event) {
        event.preventDefault();
        $(el).find('.wrapper-form-login').animate({ height: 'toggle' }, 500);
        $(el).find('.wrapper-form-recover').animate({ height: 'toggle' }, 500);
        });

        $(el).on('click', '[data-forget-password-back]', function (event) {
        event.preventDefault();
        $(el).find('.wrapper-form-login').animate({ height: 'toggle' }, 500);
        $(el).find('.wrapper-form-recover').animate({ height: 'toggle' }, 500);
        });

        $(el).on('submit', '[data-form-login]', function (event) {
        event.preventDefault();

        var $form = $(this);
        var respValidated = store.login.validateLogin($form);

        if (respValidated.validated) {
        store.login.logar($form);
        } else {
        store.setMsgResponse(respValidated.errors, 'error', $(this));
        }
        });

        $(el).on('submit', '[data-form-register]', function (event) {
        event.preventDefault();

        var $form = $(this);
        var respValidated = store.login.validateRegister($form);

        if (respValidated.validated) {
        store.login.logar($form);
        } else {
        store.setMsgResponse(respValidated.errors, 'error', $(this));
        }
        });

        $(el).on('submit', '[data-form-recover]', function (event) {
        event.preventDefault();

        var $form = $(this);
        var respValidated = store.login.validateRecover($form);

        if (respValidated.validated) {
        store.login.recoverPassord($form);
        } else {
        store.setMsgResponse(respValidated.errors, 'error', $(this));
        }
        });
      });
    }
  },
  init: function () {
    _this = this;
    //_this.setCloseBannerText();

    template_store.trocaImagemProduto();

    _this.menuMobile();
    _this.setSubmenu();
    _this.setHeaderScroll();
  //  _this.setFooterScroll();
      this.infiniteScroll.init();

    $('.carrinho').on('click', function(e){
      e.preventDefault();
      $('.modal-carrinho').addClass('open');
      $('.overlay-modal-carrinho').addClass('open');
    })

    $('.overlay-modal-carrinho').on('click', function(){
      $('.modal-carrinho').removeClass('open');
      $('.overlay-modal-carrinho').removeClass('open');
    })

    $(document).on("click", ".modal-carrinho .btn-close", function(){
      // console.log('oiiiiii')
        $('.modal-carrinho').removeClass('open');
        $('.overlay-modal-carrinho').removeClass('open');
    });

    $(document).on('click', '[data-action="delete-item-cart"]', function (event) {
      event.preventDefault();

      var $item = $(this).closest('[data-item-cart]');
      var itemId = $item.data('item-id');

      store.deleteItem(itemId, $item, function (_$item, _cart) {
        template_store.headerCart.loadCart(_cart);
      });
    });


  /*  $(window).on('load', function(){
      var alturaFooter = $('footer').height();
      alturaFooter = alturaFooter + "px";
      $('main').css("margin-bottom", alturaFooter);
    })*/

    $(document).ready(function(){
      $('.top-banner .close').on('click', function(){
        $('.top-banner').hide();
       // $('main').css("padding-top", "80px");
      })



      $('#abre-busca').click(function(){
        $('#buscar').toggleClass('aberto');
      })
      // ENVIO DE FORMULÁRIOS
      var _this =this;
      $('[data-webform]').on('submit', function (event) {
        event.preventDefault();
        var $form = $(this);

        var honeyPot = $(this).find('[name="honeypot"]');

        if (honeyPot.length > 0) {
          if (honeyPot.val() != '') {
            //console.info('ROBOT DETECTED');
            return false;
          }
        }

        if ($(this).data('webform') == 'contato') {
          var respValidated = _this.validateFormContato($(this));
          var htmlErrors = '';

          //console.info('FORM DE CONTATO', respValidated);

          if (respValidated.validated) {
            store.submitForm($(this));
            store.setMsgResponse(false, 'clear', $(this));
          } else {
            store.setMsgResponse(respValidated.errors, 'error', $(this));
          }

        } else {
          store.submitForm($(this));
        }
      });
    });

    $(function () {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 1 && $(window).scrollTop() <= ($(document).height() - 500)) {
          $('header').addClass('fixed');

        } else {
          $('header').removeClass('fixed');

        }
      });
    });

       //Troca imagem do menu

    var $img;
    var $old_img;
    var $new_img;

    var $desc;
    var $old_desc;
    var $new_desc;

    $(".item-menu").hover(
      function() {
        $img = $(this).find('.image img');
        $old_img = $img.attr('src');

        $desc = $(this).find('.image .text');
        $old_desc = $desc.html();
      }, function() {
      }
    );


    $(".item-menu .btn").hover(function () {
      $new_img = $(this).find('a').data('collection-image');
      $new_desc = $(this).find('a').data('collection-description');

      if ($new_img != '') {
        $img.attr('src', $new_img);
        $desc.html($new_desc);

      } else {
        $img.attr('src', $old_img);
        $desc.html($old_desc);
      }
    }, function () {
      $img.attr('src', $old_img);
      $desc.html($old_desc);
    });

    $('#tel-notify-when-arrives').mask('(00) 00000-0000');

  }
}
$(function (){
  template_store.init();


  if (page == 'home') {
    template_store.home.init();
  }

  if (page == 'product') {
    template_store.product.init();
  }

  if (page == 'tag') {
    template_store.tag.init();
  }

  if (page == 'login') {
    template_store.login.init();
  }
});


//console.log('%c V' + '%cnda ' + '%c- Tecnologia em e-commerce', 'color: #b24c19; font-size: 45px;', 'color: #f88d5b; font-size: 45px;', 'color: #000; font-size: 25px;');
